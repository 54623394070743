<template>
  <section v-if="state == 'ready'">
    <div v-if="isMobile">
      <EquipmentSearchPanel :source="source" />
      <div class="text-center" v-if="state == 'busy'" style="padding: 10px">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <template v-else-if="equipmentList && equipmentList.length">
        <div class="result-table" v-if="widget">
          <!-- <ConnectorListTablePanel
            :equipmentList="equipmentList"
            :panel="customPanel"
            ref="customPanel"
          /> -->
          <component
            :is="widget"
            :entrySource="equipmentList"
            :panel="customPanel"
            ref="customPanel"
          />
        </div>
        <EquipmentTablePanel
          v-else
          class="result-table"
          :equipmentList="equipmentList"
          :searchEnabled="true"
          :sortEnabled="true"
          :pagination="true"
          :clientSort="true"
          :inlineFilter="true"
          @select="onSelect"
        />
      </template>
    </div>
    <div v-else>
      <EquipmentSearchPanel
        :source="source"
        @resetQuery="onResetQuery"
        ref="searchpanel"
      />

      <template v-if="enabledItems && enabledItems.length && equipmentList">
        <div class="result-table" v-if="widget">
          <component
            :is="widget"
            :entrySource="equipmentList"
            :panel="customPanel"
            ref="customPanel"
          />
          <!-- <DeviceListTablePanel
            :equipmentList="equipmentList"
            :panel="customPanel"
            ref="customPanel"
          /> -->
        </div>
        <!-- begin file explorer -->
        <FileExplorer
          v-else
          ref="fileExplorer"
          :items="enabledItems"
          :modal="false"
          :title="
            `${equipmentListInSelectedFolder.length} ${$utils.proper(
              $tc('device', maxResult > 1 ? 2 : 1)
            )}`
          "
          :maxResult="maxResult"
          :customToolbar="sidebarToolbar"
          :multiSelection="multiSelection"
          @change="tree = $event"
          @command="onSidebarCommand"
          @drop="resetSearch"
          @beforeRestore="resetSearch"
          @sidebarResize="onSidebarResize"
          dbKey="tree_devices"
        >
          <template #search>
            <div></div>
          </template>
          <template #sidebarContent v-if="selectedSidebar == 'connectors'">
            <div class="sidebar-connector-view">
              <TreeView
                :key="filteredNodes.sid"
                :node="nodes"
                :icons="[
                  {
                    open: 'fa fa-check-square-o',
                    close: 'fa fa-square-o',
                    leafSelected: 'fa fa-check-square-o',
                    leafUnselected: 'fa fa-square-o'
                  },
                  {
                    open: 'fa fa-check-square-o',
                    close: 'fa fa-square-o',
                    leafSelected: 'fa fa-check-square-o',
                    leafUnselected: 'fa fa-square-o'
                  }
                ]"
                @click="onNodeClick($event)"
              />
            </div>
          </template>
          <template #files>
            <EquipmentTablePanel
              v-if="equipmentListInSelectedFolder.length"
              ref="resultTable"
              class="result-table"
              :equipmentList="equipmentListInSelectedFolder"
              :searchEnabled="true"
              :sortEnabled="true"
              :pagination="true"
              :clientSort="true"
              :inlineFilter="true"
              :draggableItems="
                tree && tree.show && selectedSidebar == 'folders'
              "
              @select="onSelect"
              @dragstart="
                $refs.fileExplorer.dragStart &&
                  $refs.fileExplorer.dragStart($event)
              "
              @check="onCheck($event)"
              :multiSelection="multiSelectionConfig"
            >
              <template #paginationBefore v-if="!tree || !tree.show">
                <div v-if="nodes" :class="{'pull-left': showPagination}">
                  <div
                    class="btn-sidepanel-toggle btn btn-default hidden-xs"
                    @click.stop.prevent="toogleSidebar"
                  >
                    <i class="fa fa-list"></i>
                  </div>
                  <div
                    class="btn btn-default"
                    @click="resetFilteredNodes()"
                    v-if="hasSelectedDevices"
                  >
                    <i class="fa fa-filter text-success"></i>
                  </div>
                </div>
              </template>
            </EquipmentTablePanel>
            <div
              v-else-if="
                state == 'ready' &&
                  enabledConnectorList &&
                  enabledConnectorList.length
              "
              class="alert alert-default"
              :style="styleAlertEmpty"
            >
              <div class="h3 text-center">
                {{ $t("no_equipment_was_found") }}
              </div>
            </div>
          </template>
        </FileExplorer>
        <!-- end file explorer -->
      </template>
    </div>
    <!-- -->
  </section>
</template>

<script>
import {debounce} from "lodash";
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import EquipmentTablePanel from "@/components/equipment-table-panel.vue";
import DeviceListTablePanel from "@/components/device-list-table-panel.vue";
import ConnectorListTablePanel from "@/components/connector-list-table-panel.vue";
import TreeView from "@/components/widgets/tree-view.vue";
import FileExplorer from "@/components/editor/file-explorer.vue";
import {initialUserProperties} from "@/components/editor/file-explorer.vue";
import {dftToolbar} from "@/components/editor/file-explorer-sidebar.vue";

const dftFilteredNodes = () => ({
  connector: {},
  device: {},
  show: false,
  animate: false,
  sid: 0
});

export default {
  name: "EquipmentSearchTable",
  extends: EquipmentSearchBase,
  components: {
    EquipmentTablePanel,
    TreeView,
    FileExplorer
  },
  props: {
    filterCriteria: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected",
        "process_area"
      ],
      customFields: undefined,
      tree: null,
      multiSelection: {
        all: false,
        key: "id",
        values: []
      }
    };
  },
  computed: {
    enabledItems() {
      return this.$store.getters["equipment/enabledItems"] || [];
    },
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    allEquipmentFields() {
      let self = this;
      return (
        (self.$root.config.equipment_selection &&
          self.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      let self = this;
      return this.allEquipmentFields.filter(function(i) {
        return self.fixedEquipmentProperties.indexOf(i.name) == -1;
      });
    },
    customConfiguration() {
      return (
        (this?.contract?.portal_data?.search_page || {})[this.$options.name] ||
        null
      );
    },
    widget() {
      return (
        (this.customPanel &&
          {
            DeviceListTablePanel: DeviceListTablePanel,
            ConnectorListTablePanel: ConnectorListTablePanel
          }[this.customPanel.template]) ||
        null
      );
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || null;
    },
    state() {
      return this.$store.getters["isLoading"] ||
        this.$store.getters["isIdle"] ||
        !this.contract ||
        (this.customConfiguration && this.customPanel === undefined)
        ? "busy"
        : "ready";
    },
    nodes() {
      let entry = (this.$store.getters["equipment/enabledItems"] || [])
        .filter(
          (item, ix, lst) => lst.findIndex(({id}) => id == item.id) === ix
        )
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        .map((connector) => {
          return {
            label: connector.name,
            type: {name: "connector", id: connector.id},
            children: (connector?.devices || []).map((device) => {
              return {
                label: device.name,
                type: {name: "device", id: device.id}
              };
            })
          };
        });
      if (!this.filteredNodes.sid && this.filteredNodes.connector) {
        entry.forEach((node) => {
          if ((this.filteredNodes.connector || {})[node.type.id]) {
            node.collapsed = false;
          }
          node.children.forEach((node) => {
            if ((this.filteredNodes.device || {})[node.type.id]) {
              node.collapsed = false;
            }
          });
        });
      }
      return entry;
    },
    showPagination() {
      return this.pageSize && (this.equipmentList || []).length > this.pageSize;
    },
    pageSize() {
      return parseInt(
        (this.$root.config &&
          this.$root.config.equipment_selection &&
          this.$root.config.equipment_selection.page_size) ||
          0 // no pagination
      );
    },
    hasSelectedDevices() {
      return this.$store.getters["equipment/hasSelectedDevices"];
    },
    // move it to the device explorer node component
    filteredNodesVisibility: {
      set(value) {
        var filteredNodes = this.filteredNodes;
        filteredNodes.show = value;
        if (filteredNodes.show) {
          filteredNodes.animate = true;
          if (this.hasSelectedDevices) {
            filteredNodes.sid = 0;
          }
        }
        this.filteredNodes = filteredNodes;
      },
      get() {
        return this.filteredNodes.show;
      }
    },
    filteredNodes: {
      set(value) {
        let query = JSON.parse(
          JSON.stringify(this.$store.getters["equipment/query"])
        );
        query.query_filtered_nodes = JSON.parse(JSON.stringify(value));
        this.$store.dispatch("equipment/setQuery", query);
      },
      get() {
        return (
          this.$store.getters["equipment/query"]?.query_filtered_nodes ||
          dftFilteredNodes()
        );
      }
    },
    maxResult() {
      return (this.equipmentList || []).length;
    },
    sidebarToolbar() {
      return this.tree
        ? {
            connectors: {
              toolbar: [
                {
                  id: "reset",
                  icon: "fa fa-filter text-success",
                  title: "reset",
                  command: "reset",
                  visible: this.hasSelectedDevices,
                  enabled: true,
                  pending: false
                },
                {
                  id: "folders",
                  icon: "fa fa-folder-o",
                  title: "folder",
                  command: "folders",
                  visible: true,
                  enabled: true,
                  pending: false
                }
              ]
            },
            folders: {
              toolbar: [
                {
                  id: "connector",
                  icon: "fa fa-plug",
                  title: "connector",
                  command: "connectors",
                  visible: true,
                  enabled: true,
                  pending: false
                },
                ...(this.$refs.fileExplorer
                  ? this.$refs.fileExplorer.standardToolbar
                  : dftToolbar())
              ]
            }
          }[this.selectedSidebar].toolbar
        : null;
    },
    equipmentListInSelectedFolder() {
      return !this.tree ||
        !this.tree.show ||
        this.selectedSidebar == "connectors"
        ? this.equipmentList || []
        : (this.equipmentList || []).filter(
            (item) =>
              this.tree.selectedNode === "" ||
              this.tree.selectedNode === this.tree.leaves[item.id]
          );
    },
    selectedSidebar: {
      set(value) {
        if (!this.tree || !this.$refs.fileExplorer) return;
        this.$refs.fileExplorer.userProperties = {
          ...(this.$refs.fileExplorer.userProperties || {}),
          ...{selectedSidebar: value}
        };
      },
      get() {
        if (this.tree && this.$refs.fileExplorer) {
          return (
            (this.$refs.fileExplorer.userProperties || {})["selectedSidebar"] ??
            "connectors"
          );
        }
        return (
          initialUserProperties("tree_devices")["selectedSidebar"] ??
          "connectors"
        );
      }
    },
    multiSelectionConfig() {
      return this.source == "device" && this.selectedSidebar == "folders"
        ? this.multiSelection
        : {key: null};
    },
    folderMode() {
      return this.tree && this.tree.show && this.$refs.fileExplorer
        ? true
        : false;
    },
    selectedNode() {
      return (this.folderMode && this.tree.selectedNode) || "";
    },
    isMobile() {
      return this.$utils.isMobile();
    },
    fields() {
      return (this.customPanel && this.customFields) || this.defaultFields();
    },
    styleAlertEmpty() {
      if (this.$root.isDarkTheme) {
        return {
          'color': 'var(--skin-dark-light)',
          'background-color': 'var(--skin-dark-darker)'
        }
      }
      return {
        'background-color': 'whitesmoke'
      }
    }
  },
  watch: {
    customConfiguration: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    },
    screens: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    },
    nConnectors() {
      this.highLightFolders();
    },
    folderMode(n, o) {
      if (!o && n && this.isFiltering()) {
        this.highLightFolders();
      }
    }
  },
  methods: {
    onSelect(query) {
      if (query) {
        if (query.connector_id) {
          this.showEquipmentWithId(query.connector_id);
        }
      }
    },
    showField(name) {
      return (
        this.allEquipmentFields.filter(function(i) {
          return i.name == name;
        }).length > 0
      );
    },
    customTemplate() {
      return (
        this.$store.getters["dashboard/template"](
          this.customConfiguration.screen_id
        ) || null
      );
    },
    setCustomPanel(template) {
      if (!this.customPanel) {
        let panel =
          ((template && template?.panels) || []).find(
            ({name}) => name == this.customConfiguration.panel_name
          ) || null; // important - default null would render the default layout
        this.$set(this, "customPanel", panel);
        this.$nextTick(() => {
          if (this.$refs.customPanel && this.$refs.customPanel.fields) {
            this.customFields = this.$refs.customPanel.fields;
          }
        });
      }
    },
    async updateCustomView() {
      if (
        !this?.customConfiguration?.screen_id ||
        !(this.screens || []).some(
          ({id}) =>
            parseInt(id) == parseInt(this?.customConfiguration?.screen_id)
        )
      )
        return;
      let template = this.customTemplate();
      if (template) {
        this.setCustomPanel(template);
      } else {
        await this.$store
          .dispatch(
            "dashboard/fetchTemplate",
            this.customConfiguration.screen_id
          )
          .then((response) => {
            if (response) {
              this.setCustomPanel(response);
            }
          });
      }
    },
    onNodeClick($event) {
      var opened = $event.action == "nodeOpen" ? true : false;
      var node = $event.node;
      var filteredNodes = JSON.parse(JSON.stringify(this.filteredNodes));
      filteredNodes[node.type.name][node.type.id] = opened;
      if (!opened && (node.children || []).length) {
        node.children.forEach((node) => {
          filteredNodes[node.type.name][node.type.id] = opened;
        });
      }
      this.filteredNodes = filteredNodes;
    },
    resetFilteredNodes() {
      let filteredNodes = {
        ...dftFilteredNodes(),
        animate: true,
        show: this.filteredNodes.show,
        sid: this.filteredNodes.sid + 1
      };
      this.filteredNodes = filteredNodes;
    },
    onSidebarCommand(command) {
      switch (command) {
        case "connectors":
          this.selectedSidebar = command;
          // this.filteredNodesVisibility = true;
          if (this._filteredNodes) {
            this.filteredNodes = JSON.parse(this._filteredNodes);
            this._filteredNodes = null;
          }
          break;
        case "folders":
          // important since equipment list must have all leaves available on folder build in routine
          // this.$root.$emit("query:reset");
          this.selectedSidebar = command;
          this.$nextTick(() => {
            this._filteredNodes = JSON.stringify(this.filteredNodes);
            this.resetFilteredNodes();
          });
          break;
        case "reset":
          this.resetFilteredNodes();
          break;
      }
    },
    isFiltering() {
      let stats = this.$store.getters["equipment/stats"];
      return stats.available > (this.equipmentList || []).length;
    },
    async highLightFolders() {
      if (!this.folderMode) return;
      this._highLightFolders =
        this._highLightFolders ||
        debounce(() => {
          let lst = this.isFiltering()
            ? (this.equipmentList || []).map(({id}) => id)
            : null;
          if (this.$refs.fileExplorer)
            this.$refs.fileExplorer.findNodesByLeafId(lst);
        }, 100);
      this._highLightFolders();
    },
    onResetQuery() {
      this.$nextTick(() => {
        if (!this.folderMode) return;
        this.multiSelection.all = false;
        this.multiSelection.values = [];
        // this.$refs.fileExplorer.restore();
      });
    },
    onCheck(id) {
      if (id) {
        // check/uncheck single item
        let len = this.multiSelection.values.length;
        this.multiSelection.values = this.multiSelection.values.filter(
          (i) => parseInt(id) !== parseInt(i)
        );
        if (this.multiSelection.values.length == len) {
          this.multiSelection.values.push(id);
        }
      } else {
        // check/uncheck all
        this.multiSelection.all = !this.multiSelection.all;
        this.multiSelection.values = [];
        if (this.multiSelection.all) {
          if (this.tree.selectedNode) {
            this.multiSelection.values = Object.keys(this.tree.leaves).filter(
              (id) =>
                this.tree.leaves[id] == this.tree.selectedNode &&
                this.equipmentList.find(
                  (item) => parseInt(item.id) == parseInt(id)
                )
            );
          } else {
            this.multiSelection.values = this.multiSelection.all
              ? this.equipmentList.map(({id}) => id)
              : [];
          }
        }
      }
    },
    resetSearch() {
      this.multiSelection.all = false;
      this.multiSelection.values = [];
      if (this.$refs.searchpanel) {
        this.$refs.searchpanel.queryString = "";
        this.$refs.searchpanel.focus();
      }
    },
    toogleSidebar() {
      // if (this.$refs.searchpanel) {
      //   this.$refs.searchpanel.resetQuery();
      // }
      this.$nextTick(() => {
        if (this.$refs.fileExplorer && this.$refs.fileExplorer.toggle) {
          this.$refs.fileExplorer.toggle();
        }
      });
    },
    onSidebarResize() {
      this.$nextTick(() => {
        if (this.$refs.resultTable) {
          this.$refs.resultTable.syncColumnResizeHandle();
        }
      });
    }
  },
  mounted() {
    this.refreshEnabled = true;
  },
  created() {
    this.filteredNodes.sid = 0;
    this.filteredNodes.animate = false;
  }
};
</script>
<style scoped>
.tab-search {
  position: relative;
}
.result-table {
  min-height: 400px;
}

.btn.text-success {
  color: #59a05b;
}

.btn-sidepanel-toggle {
  margin: 0 25px 0 10px;
}

/* new - with folder */
.sidebar-connector-view {
  clear: both;
  padding: 5px 0 0 10px;
  font-size: 14px;
  min-width: 100px;
}
</style>
